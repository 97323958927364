header {
  #headertopbar {
    border-bottom: 1px solid #444;

    .leftbar a {
      display: flex;
      flex-direction: row;
      justify-content: center;

      p,
      svg {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      svg {
        width: 25px;
        height: 30px;
        margin-right: 5px;
      }

      p {
        font-size: 16px;
        color: #444;
        font-weight: 600;
        text-align: center;
      }
    }

    .rightbar {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .locationtext {
        margin-left: 5px;
        font-style: italic;
        font-weight: 400;
      }

      svg,
      p {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      svg {
        width: 11px;
        height: 33px;
        margin-right: 5px;
      }

      p {
        font-size: 16px;
        text-align: center;
        font-weight: 600;
      }
    }

    p {
      margin-bottom: 0;
    }

    svg {
      fill: #444;
    }
  }

  .logo {
    width: auto;
    max-height: 110px;
    margin: auto;
    display: block;
  }
}